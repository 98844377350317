import React, { useEffect, useState } from "react";

const LeadFormField: React.FC<{
  id: number;
  profile_id: number;
  required: boolean;
  title: string;
  value: string;
  error: boolean;
  handleChange: (id: number, value: string) => void;
}> = ({ id, profile_id, required, title, value, error, handleChange }) => {
  const [state, setState] = useState("");
  useEffect(() => {
    handleChange(id, state);
  }, [state]);
  return (
    <div
      className={`${
        error ? "border-red-600" : ""
      } w-full border-2 flex items-center rounded-lg px-1 mb-2`}
    >
      <input
        type="text"
        placeholder={title}
        className={`${
          error ? "border-red-600" : ""
        }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
        value={state}
        onChange={(e) => setState(e.target.value)}
      />
    </div>
  );
};

export default LeadFormField;
