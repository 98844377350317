import React, { FormEvent, useEffect, useReducer } from "react";
import { useState } from "react";
import emailImg from "../assets/email.png";
import nameImg from "../assets/name.png";
import messageImg from "../assets/message.png";
import phoneImg from "../assets/phone.png";
import CircularLoading from "./CircularLoading";
import axios from "axios";
import { Rating } from "@mui/material";
import { StarIcon } from "lucide-react";

interface FormFieldState {
  id: number;
  index: number;
  is_active: boolean;
  is_required: boolean;
  label: string;
  options: {
    placeholder?: string;
    max_rate_value?: number;
  };
  type: {
    id: number;
    name: string;
    code: "text" | "email" | "rating" | "number" | "name";
  };
  type_id: number;
  value: string | number | null;
  error: string;
}

type FormAction =
  | {
      type: "INITIALIZE_FORM_FIELDS";
      payload: {
        id: number;
        index: number;
        is_active: boolean;
        is_required: boolean;
        label: string;
        options: {
          placeholder?: string;
          max_rate_value?: number;
        };
        type: {
          id: number;
          name: string;
          code: "text" | "email" | "rating" | "number" | "name";
        };
        type_id: number;
      }[];
    }
  | {
      type: "UPDATE_FIELD_VALUE";
      id: number;
      value: string | number;
    }
  | { type: "ADD_ERROR"; id: number; message: string };
const formFieldsReducer = (
  state: FormFieldState[],
  action: FormAction
): FormFieldState[] => {
  switch (action.type) {
    case "INITIALIZE_FORM_FIELDS":
      return action.payload.map((field) => ({
        ...field,
        error: "",
        value: null,
      }));

    case "UPDATE_FIELD_VALUE":
      return state.map((field) =>
        field.id === action.id ? { ...field, value: action.value } : field
      );
    case "ADD_ERROR":
      return state.map((field) =>
        field.id === action.id ? { ...field, error: action.message } : field
      );

    default:
      return state;
  }
};

const FormField: React.FC<{
  id: number;
  title: string;
  profile_id: number | undefined;
  formFields: {
    id: number;
    index: number;
    is_active: boolean;
    is_required: boolean;
    label: string;
    options: {
      placeholder?: string;
      max_rate_value?: number;
    };
    type: {
      id: number;
      name: string;
      code: "text" | "email" | "rating" | "number" | "name";
    };
    type_id: number;
  }[];
}> = ({ title, profile_id, formFields, id }) => {
  const [fields, dispatch] = useReducer(formFieldsReducer, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  useEffect(() => {
    dispatch({ type: "INITIALIZE_FORM_FIELDS", payload: formFields });
  }, [formFields]);
  console.log({
    fields,
  });

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    let hasErrors = false;
    fields.forEach((field) => {
      if (field.is_required && (field.value === null || field.value === "")) {
        dispatch({
          type: "ADD_ERROR",
          id: field.id,
          message: `${field.label || field.id} is required.`,
        });
        hasErrors = true;
      }

      switch (field.type.code) {
        case "email":
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(field.value as string)) {
            dispatch({
              type: "ADD_ERROR",
              id: field.id,
              message: "Invalid email address.",
            });
            hasErrors = true;
          }
          break;

        case "name":
          const nameRegex = /^[a-zA-Z\s]*$/;
          if (!nameRegex.test(field.value as string)) {
            dispatch({
              type: "ADD_ERROR",
              id: field.id,
              message: "Name can only contain letters and spaces.",
            });
            hasErrors = true;
          }
          break;

        case "rating":
          if (
            field.options.max_rate_value &&
            (field.value as number) > (field.options.max_rate_value || 5)
          ) {
            dispatch({
              type: "ADD_ERROR",
              id: field.id,
              message: `Rating cannot exceed ${field.options.max_rate_value}.`,
            });
            hasErrors = true;
          }
          break;

        case "number":
          const numberRegex = /^\+?\d+$/;
          if (!numberRegex.test(field.value as string)) {
            dispatch({
              type: "ADD_ERROR",
              id: field.id,
              message: "Number can only contain digits.",
            });
            hasErrors = true;
          }
          break;
        default:
          break;
      }
    });
    if (hasErrors) return;
    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/links/form-link/submit/${id}`,
      {
        values: fields.map((field) => {
          return {
            label: field.label,
            value: field.value,
          };
        }),
      }
    );
    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
    console.log(res);
  };

  return (
    <form className=" flex flex-col px-6 font-trial w-full  mb-6">
      <div className="flex flex-col justify-center px-5 py-5 items-center font-trial bg-gray-100 rounded-2xl">
        <h3 className="text-lg font-semibold capitalize mb-4 w-full text-center px-4">
          {title}
        </h3>
        {fields.map((field) => {
          if (field.type.code === "email") {
            return (
              <div
                key={field.id}
                className={`${
                  field.error ? "border-red-600" : ""
                } w-full border-2 flex items-center rounded-lg px-3 mb-2`}
              >
                <img
                  src={emailImg}
                  className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
                  alt=""
                />
                <input
                  type="Email"
                  placeholder={field.options.placeholder || field.label}
                  className={`${
                    field.error ? "border-red-600" : ""
                  }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
                  value={(field.value as string) || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "UPDATE_FIELD_VALUE",
                      id: field.id,
                      value: e.target.value,
                    });
                  }}
                  onFocus={() => {
                    dispatch({
                      type: "ADD_ERROR",
                      id: field.id,
                      message: "",
                    });
                  }}
                />
              </div>
            );
          } else if (field.type.code === "name") {
            return (
              <div
                key={field.id}
                className={`${
                  field.error ? "border-red-600" : ""
                } w-full border-2 flex items-center rounded-lg px-3 mb-2`}
              >
                <img
                  src={nameImg}
                  className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
                  alt=""
                />
                <input
                  type="text"
                  placeholder={field.options.placeholder || field.label}
                  className={`${
                    field.error ? "border-red-600" : ""
                  }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
                  value={(field.value as string) || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "UPDATE_FIELD_VALUE",
                      id: field.id,
                      value: e.target.value,
                    });
                  }}
                  onFocus={() => {
                    dispatch({
                      type: "ADD_ERROR",
                      id: field.id,
                      message: "",
                    });
                  }}
                />
              </div>
            );
          } else if (field.type.code === "number") {
            return (
              <div
                key={field.id}
                className={`${
                  field.error ? "border-red-600" : ""
                } w-full border-2 flex items-center rounded-lg px-3 mb-2`}
              >
                <img
                  src={phoneImg}
                  className="w-[1.2rem] h-[1.2rem]  top-[25%] left-[10px] rayscale opacity-[0.4]"
                  alt=""
                />
                <input
                  type="tel"
                  placeholder={field.options.placeholder || field.label}
                  className={`${
                    field.error ? "border-red-600" : ""
                  }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
                  value={(field.value as string) || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "UPDATE_FIELD_VALUE",
                      id: field.id,
                      value: e.target.value,
                    });
                  }}
                  onFocus={() => {
                    dispatch({
                      type: "ADD_ERROR",
                      id: field.id,
                      message: "",
                    });
                  }}
                />
              </div>
            );
          } else if (field.type.code === "text") {
            return (
              <div
                key={field.id}
                className={`${
                  field.error ? "border-red-600" : ""
                } w-full border-2 flex items-center rounded-lg px-3 mb-2`}
              >
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Edit / Text_Align_Left">
                      <path
                        id="Vector"
                        d="M4 18H14M4 14H20M4 10H14M4 6H20"
                        stroke="#9A9B9C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </g>
                </svg>
                <input
                  type="text"
                  placeholder={field.options.placeholder || field.label}
                  className={`${
                    field.error ? "border-red-600" : ""
                  }   border-gray-300 outline-none px-2 py-2 text-md bg-transparent placeholder:text-sm w-full`}
                  value={(field.value as string) || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "UPDATE_FIELD_VALUE",
                      id: field.id,
                      value: e.target.value,
                    });
                  }}
                  onFocus={() => {
                    dispatch({
                      type: "ADD_ERROR",
                      id: field.id,
                      message: "",
                    });
                  }}
                />
              </div>
            );
          } else if (field.type.code === "rating") {
            return (
              <div
                key={field.id}
                className="flex flex-col gap-y- w-full justify-start items-start"
              >
                <h1 className="text-gray-400 font-semibold ">{field.label}</h1>
                <div
                  className={`${
                    field.error ? "border-red-600" : ""
                  } w-full flex items-center rounded-lg px- mb-2`}
                >
                  <Rating
                    name={`rating-${field.id}`}
                    value={(field.value as number) || 0}
                    precision={0.5}
                    onChange={(event, newValue) => {
                      dispatch({
                        type: "ADD_ERROR",
                        id: field.id,
                        message: "",
                      });
                      if (
                        field.options.max_rate_value &&
                        (newValue as number) > field.options.max_rate_value
                      )
                        return dispatch({
                          type: "UPDATE_FIELD_VALUE",
                          id: field.id,
                          value: field.options.max_rate_value,
                        });
                      dispatch({
                        type: "UPDATE_FIELD_VALUE",
                        id: field.id,
                        value: newValue as number,
                      });
                    }}
                    onFocus={() => {}}
                    emptyIcon={
                      <StarIcon
                        style={{
                          opacity: 0.55,
                          color: field.error ? "#FF0000" : "",
                        }}
                        fontSize="inherit"
                      />
                    }
                  />
                </div>
              </div>
            );
          }
        })}

        <button
          onClick={submitHandler}
          className={`${
            false ? "bg-[#222]" : "bg-black"
          } rounded-full h-[2.8rem] w-[9.5rem] mt-2  outline-none  text-white flex justify-center items-center font-semibold drop-shadow-xl ${
            success ? "bg-green-600 text-white" : ""
          } transition-all`}
        >
          {loading ? (
            <CircularLoading button={true} noPanner={true} panner="" />
          ) : (
            <span>{success ? "Connectd" : "Submit"}</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default FormField;
