import React from "react";
import { useState } from "react";
const ExtendableText: React.FC<{
  title: string;
  question: string;
  answer: string;
}> = ({ title, question, answer }) => {
  const [extend, setExtend] = useState<boolean>(false);
  return (
    <div className="w-full px-5">
      <h1 className="text mb-2 font-semibold capitalize w-full text-start  leading-6 mt-2">{title}</h1>
      <div className=" w-full">
        <div
          className="flex justify-between items-center px-5 mb-3  py-3 bg-white drop-shadow rounded-xl"
          onClick={() => {
            setExtend((prevState) => !prevState);
          }}
        >
          <h3 className="text-md font-semibold ">{question}</h3>
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              transform={extend ? "rotate(0)" : "rotate(-90)"}
              style={{
                transition: "transform 0.4s ease",
                outline: "none",
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z"
                fill="#000000"
              />
            </svg>
          </div>
        </div>
        <h3
          className={`py-3  ${
            extend ? "block" : "hidden"
          } h-auto extend text-black/60 text-sm bg-gray-100 rounded-xl px-5`}
        >
          {answer}
        </h3>
      </div>
    </div>
  );
};

export default ExtendableText;
