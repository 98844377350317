import React from "react";

const TextField: React.FC<{ description: string }> = ({ description }) => {
  return (
    <h3
      className={` h-auto extend text-black/60 text-lg text-center px-5 w-full leading-5 `}
    >
      {description}
    </h3>
  );
};

export default TextField;
