import React from "react";

const Heading: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <h3 className="text-2xl font-semibold capitalize w-full text-center px-4 leading-6 mt-2">
      {title}
    </h3>
  );
};

export default Heading;
