import React from "react";

const ImageField:React.FC<{target: string}> = ({
    target
}) => {
  return (
    <div className="w-full h-auto px-4">
      <img className="w-full h-full rounded-xl drop-shadow-lg object-contain " src={target} />
    </div>
  );
};

export default ImageField;
